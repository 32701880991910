import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface IOverviewData {
  overview: IOverview;
  activeLang: string;
  setActiveLang: React.Dispatch<React.SetStateAction<string>>;
  setOverview: React.Dispatch<React.SetStateAction<IOverview>>;
}
export interface IOverview {
  sub_title_en: string;
  sub_title_tc: string;
  sub_title_sc: string;

  title_en: string;
  title_tc: string;
  title_sc: string;

  description_en: string;
  description_tc: string;
  description_sc: string;

  passenger_service_title_en: string;
  passenger_service_title_tc: string;
  passenger_service_title_sc: string;

  passenger_service_text_en: string;
  passenger_service_text_tc: string;
  passenger_service_text_sc: string;

  ramp_service_title_en: string;
  ramp_service_title_tc: string;
  ramp_service_title_sc: string;

  ramp_service_text_en: string;
  ramp_service_text_tc: string;
  ramp_service_text_sc: string;

  passenger_service_image_en: File | undefined;
  passenger_service_image_url_en: string;
  ramp_service_image_en: File | undefined;
  ramp_service_image_url_en: string;
  passenger_service_image_tc: File | undefined;
  passenger_service_image_url_tc: string;
  ramp_service_image_tc: File | undefined;
  ramp_service_image_url_tc: string;
  passenger_service_image_sc: File | undefined;
  passenger_service_image_url_sc: string;
  ramp_service_image_sc: File | undefined;
  ramp_service_image_url_sc: string;
}
const OverviewSection: FC<IOverviewData> = ({
  overview,
  activeLang,
  setActiveLang,
  setOverview
}) => {
  const description_en_Ref = useRef();
  const description_tc_Ref = useRef();
  const description_sc_Ref = useRef();
  const [data, setData] = useState<IOverview>({
    sub_title_en: '',
    sub_title_tc: '',
    sub_title_sc: '',
    title_en: '',
    title_tc: '',
    title_sc: '',
    description_en: '',
    description_tc: '',
    description_sc: '',
    passenger_service_title_en: '',
    passenger_service_title_tc: '',
    passenger_service_title_sc: '',
    passenger_service_text_en: '',
    passenger_service_text_tc: '',
    passenger_service_text_sc: '',
    ramp_service_title_en: '',
    ramp_service_title_tc: '',
    ramp_service_title_sc: '',
    ramp_service_text_en: '',
    ramp_service_text_tc: '',
    ramp_service_text_sc: '',
    passenger_service_image_en: undefined,
    passenger_service_image_url_en: '',
    ramp_service_image_en: undefined,
    ramp_service_image_url_en: '',
    passenger_service_image_tc: undefined,
    passenger_service_image_url_tc: '',
    ramp_service_image_tc: undefined,
    ramp_service_image_url_tc: '',
    passenger_service_image_sc: undefined,
    passenger_service_image_url_sc: '',
    ramp_service_image_sc: undefined,
    ramp_service_image_url_sc: '',
  });
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  useEffect(() => {
    if (overview) {
      setData(overview);
    }
  }, [overview]);
  const change_data = (value: any, key: string) => {
    if (overview) {
      if (key == "ramp_service_image_en") {
        setOverview((prev) => {
          return {
            ...prev,
            ramp_service_image_en: value,
            ramp_service_image_url_en: value ? URL.createObjectURL(value) : '',
          }
        });
        return;
      }
      if (key == "passenger_service_image_en") {
        setOverview((prev) => {
          return {
            ...prev,
            passenger_service_image_en: value,
            passenger_service_image_url_en: value ? URL.createObjectURL(value) : '',
          }
        });
        return;
      }
      if (key == "ramp_service_image_tc") {
        setOverview((prev) => {
          return {
            ...prev,
            ramp_service_image_tc: value,
            ramp_service_image_url_tc: value ? URL.createObjectURL(value) : '',
          }
        });
        return;
      }
      if (key == "passenger_service_image_tc") {
        setOverview((prev) => {
          return {
            ...prev,
            passenger_service_image_tc: value,
            passenger_service_image_url_tc: value ? URL.createObjectURL(value) : '',
          }
        });
        return;
      }
      if (key == "ramp_service_image_sc") {
        setOverview((prev) => {
          return {
            ...prev,
            ramp_service_image_sc: value,
            ramp_service_image_url_sc: value ? URL.createObjectURL(value) : '',
          }
        });
        return;
      }
      if (key == "passenger_service_image_sc") {
        setOverview((prev) => {
          return {
            ...prev,
            passenger_service_image_sc: value,
            passenger_service_image_url_sc: value ? URL.createObjectURL(value) : '',
          }
        });
        return;
      }
      setOverview({ ...overview, [key]: value });
    }
  };
  return (
    <div>
      <div className="mt-5">
        <div
          className={
            "space-y-5" +
            twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )
          }
        >
          <LabelAndTextbox
            label="Sub Title (EN)"
            value={data?.sub_title_en}
            setValue={(value) => change_data(value, "sub_title_en")}
          />
          <LabelAndTextbox
            label="Title (EN)"
            value={data?.title_en}
            setValue={(value) => change_data(value, "title_en")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value) => change_data(value, "description_en")}
          />

          <LabelAndTextbox
            label="Passenger Service Title (EN)"
            value={data?.passenger_service_title_en}
            setValue={(value) =>
              change_data(value, "passenger_service_title_en")
            }
          />
          <LabelAndTextbox
            label="Passenger Service Text (EN)"
            value={data?.passenger_service_text_en}
            setValue={(value) =>
              change_data(value, "passenger_service_text_en")
            }
          />

          <LabelAndTextbox
            label="Ramp Service Title (EN)"
            value={data?.ramp_service_title_en}
            setValue={(value) => change_data(value, "ramp_service_title_en")}
          />
          <LabelAndTextbox
            label="Ramp Service Text (EN)"
            value={data?.ramp_service_text_en}
            setValue={(value) => change_data(value, "ramp_service_text_en")}
          />
          <LabelAndImage
            margin="mt-5"
            setFile={(value: any) =>
              change_data(value, "passenger_service_image_en")
            }
            label="Passenger Service Image (EN)"
            value={data?.passenger_service_image_url_en}
          />
          <LabelAndImage
            margin="my-5"
            setFile={(value: any) => change_data(value, "ramp_service_image_en")}
            label="Ramp Service Image (EN)"
            value={data?.ramp_service_image_url_en}
          />
        </div>

        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Sub Title (TC)"
            value={data?.sub_title_tc}
            setValue={(value) => change_data(value, "sub_title_tc")}
          />
          <LabelAndTextbox
            label="Title (TC)"
            value={data?.title_en}
            setValue={(value) => change_data(value, "title_tc")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (TC)"
            value={data?.description_en}
            setValue={(value) => change_data(value, "description_tc")}
          />

          <LabelAndTextbox
            label="Passenger Service Title (TC)"
            value={data?.passenger_service_title_tc}
            setValue={(value) =>
              change_data(value, "passenger_service_title_tc")
            }
          />
          <LabelAndTextbox
            label="Passenger Service Text (TC)"
            value={data?.passenger_service_text_tc}
            setValue={(value) =>
              change_data(value, "passenger_service_text_tc")
            }
          />

          <LabelAndTextbox
            label="Ramp Service Title (TC)"
            value={data?.ramp_service_title_en}
            setValue={(value) => change_data(value, "ramp_service_title_tc")}
          />
          <LabelAndTextbox
            label="Ramp Service Text (TC)"
            value={data?.ramp_service_text_tc}
            setValue={(value) => change_data(value, "ramp_service_text_tc")}
          />
          <LabelAndImage
            margin="mt-5"
            setFile={(value: any) =>
              change_data(value, "passenger_service_image_tc")
            }
            label="Passenger Service Image (TC)"
            value={data?.passenger_service_image_url_tc}
          />
          <LabelAndImage
            margin="my-5"
            setFile={(value: any) => change_data(value, "ramp_service_image_tc")}
            label="Ramp Service Image (TC)"
            value={data?.ramp_service_image_url_tc}
          />
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Sub Title (SC)"
            value={data?.sub_title_sc}
            setValue={(value) => change_data(value, "sub_title_sc")}
          />
          <LabelAndTextbox
            label="Title (SC)"
            value={data?.title_sc}
            setValue={(value) => change_data(value, "title_sc")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (SC)"
            value={data?.description_sc}
            setValue={(value) => change_data(value, "description_sc")}
          />

          <LabelAndTextbox
            label="Passenger Service Title (SC)"
            value={data?.passenger_service_title_sc}
            setValue={(value) =>
              change_data(value, "passenger_service_title_sc")
            }
          />
          <LabelAndTextbox
            label="Passenger Service Text (SC)"
            value={data?.passenger_service_text_sc}
            setValue={(value) =>
              change_data(value, "passenger_service_text_sc")
            }
          />

          <LabelAndTextbox
            label="Ramp Service Title (SC)"
            value={data?.ramp_service_title_sc}
            setValue={(value) => change_data(value, "ramp_service_title_sc")}
          />
          <LabelAndTextbox
            label="Ramp Service Text (SC)"
            value={data?.ramp_service_text_sc}
            setValue={(value) => change_data(value, "ramp_service_text_sc")}
          />
          <LabelAndImage
            margin="mt-5"
            setFile={(value: any) =>
              change_data(value, "passenger_service_image_sc")
            }
            label="Passenger Service Image (SC)"
            value={data?.passenger_service_image_url_sc}
          />
          <LabelAndImage
            margin="my-5"
            setFile={(value: any) => change_data(value, "ramp_service_image_sc")}
            label="Ramp Service Image (SC)"
            value={data?.ramp_service_image_url_sc}
          />
        </div>


      </div>
    </div>
  );
};
export default OverviewSection;
